import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContractState, Contract } from "../../redux/contracts/contracts-types";
import { CreateUserDpaModal } from "./CreateUserDpaModal";
import { CreateCompanyDpaModal } from "./CreateCompanyDpaModal";
import { colors } from "../../theme";
import DoneIcon from "@mui/icons-material/Done";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeadCell,
    TableRow,
} from "../../components/Table";
import { TFunction } from "i18next";
import { Button } from "../../components/Button";
import { useAppSelector } from "../../utils/store-helpers";
import { ViewContainer } from "../../components/ViewContainer";
import { useDispatch } from "react-redux";
import { FetchUserContractsAction } from "../../redux/contracts/contracts-actions";
import { FetchCompaniesWithoutAlarmsAction } from "../../redux/companies/companies-actions";
import { CreateCompanySubscriptionModal } from "./CreateCompanySubscriptionModal";
import SendSignRequestButton from "./SendSignRequestButton";
import { Block } from "../../components/common/block";
import ExpandableBlock from "../../components/ExpandableBlock";
import { Paper, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function translatedContractState(t: TFunction<"translation", undefined>, state: ContractState | undefined): string {
    switch (state) {
        case undefined:
            return t("contracts.state.MISSING");
        case ContractState.PENDING:
            return t("contracts.state.PENDING");
        case ContractState.CANCELLED:
            return t("contracts.state.CANCELLED");
        case ContractState.ACTIVE:
            return t("contracts.state.ACTIVE");
        case ContractState.UNKNOWN:
            return t("contracts.state.UNKNOWN");
    }
}

function contractStateColor(state: ContractState | undefined): React.CSSProperties {
    switch (state) {
        case undefined:
            return { color: colors.rowColorRemove };
        case ContractState.ACTIVE:
            return { color: colors.success06 };
        case ContractState.CANCELLED:
            return { color: colors.rowColorRemove };
        case ContractState.PENDING:
            return { color: colors.rowColorRemove };
    }
    return {};
}

function contractToShow(contracts: Contract[]): Contract | null {
    const mostRecentFirst = [...contracts].sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    );
    const active = mostRecentFirst.find((contract) => contract.state === ContractState.ACTIVE);
    if (active !== undefined) {
        return active;
    }
    const pending = mostRecentFirst.find((contract) => contract.state === ContractState.PENDING);
    if (pending !== undefined) {
        return pending;
    }
    return null;
}

type ContractsTableProps = {
    rows: ContractsTableRow[];
};

type ContractsTableRow = {
    key: number;
    type: string;
    contract: Contract | null;
    signActionDisabledBecause: string | null;
    onSignClicked: () => void;
};

function ContractsTable({ rows }: ContractsTableProps): JSX.Element {
    const t = useTranslation().t;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeadCell>{t("contracts.table.header.contractType")}</TableHeadCell>
                        <TableHeadCell>{t("contracts.table.header.state")}</TableHeadCell>
                        <TableHeadCell>{t("contracts.table.header.updatedAt")}</TableHeadCell>
                        <TableHeadCell></TableHeadCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.key}>
                            <TableCell>{row.type}</TableCell>

                            <TableCell style={contractStateColor(row.contract?.state)}>
                                {translatedContractState(t, row.contract?.state)}
                            </TableCell>

                            <TableCell>
                                {row.contract?.updatedAt !== undefined
                                    ? new Date(row.contract.updatedAt).toLocaleString()
                                    : "-"}
                            </TableCell>

                            <TableCell>
                                <ActionButton
                                    contract={row.contract}
                                    signActionDisabledBecause={row.signActionDisabledBecause}
                                    onSignClicked={row.onSignClicked}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type ActionButtonProps = {
    contract: Contract | null;
    signActionDisabledBecause: string | null;
    onSignClicked: () => void;
};

function ActionButton({ contract, signActionDisabledBecause, onSignClicked }: ActionButtonProps): JSX.Element | null {
    const t = useTranslation().t;

    if (contract?.state === ContractState.ACTIVE) {
        // we already have a signed contract, no further actions
        return null;
    }

    if (contract?.state === ContractState.PENDING) {
        // contract exists but is not yet signed
        return <SendSignRequestButton contractId={contract.id} contractState={contract.state} />;
    }

    if (contract === null || contract?.state === ContractState.CANCELLED) {
        // contract does not exist (cancelled or not even created)
        if (signActionDisabledBecause !== null) {
            console.log("DISABLED");
            return (
                <Tooltip title={signActionDisabledBecause}>
                    <div>
                        {/* wrapped in div so that Tooltip works */}
                        <Button disabled>{t("contracts.table.action.sign")}</Button>
                    </div>
                </Tooltip>
            );
        }
        return (
            <Button variant="contained" onClick={onSignClicked}>
                {t("contracts.table.action.sign")}
            </Button>
        );
    }

    // don't know what can be allowed, so don't allow anything
    return null;
}

type CompanyContractStatusIconProps = {
    allContractsSigned: boolean;
};

function CompanyContractStatusIcon({ allContractsSigned }: CompanyContractStatusIconProps): JSX.Element {
    const t = useTranslation().t;
    if (allContractsSigned) {
        return (
            <Tooltip title={t("contracts.tooltips.allCompanyContractsSigned")}>
                <DoneIcon className="text-green" style={{ verticalAlign: "middle" }} />
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={t("contracts.tooltips.notAllCompanyContractsSigned")}>
                <FontAwesomeIcon color={colors.warm06} icon={faTriangleExclamation} />
            </Tooltip>
        );
    }
}

export type Props = {
    userId: number;
};

export default function ContractsView({ userId }: Props): JSX.Element {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    useEffect(() => {
        if (userId !== 0) {
            dispatch(FetchUserContractsAction({ userId }));
            dispatch(FetchCompaniesWithoutAlarmsAction());
        }
    }, [dispatch, userId]);

    const companies = useAppSelector((state) => state.hydrolink.companies.companies);
    const principallyManagedCompanies = useMemo(
        () =>
            companies
                .filter((company) =>
                    company.managers.some(
                        (manager) => manager.userId === userId && manager.role === "manager" && manager.principal,
                    ),
                )
                .sort((a, b) => a.name.localeCompare(b.name)),
        [companies, userId],
    );

    const [isUserDpaModalOpen, setIsUserDpaModalOpen] = useState(false);
    const [isCompanySubscriptionModalOpenById, setIsCompanySubscriptionModalOpenById] = useState<
        Record<number, boolean>
    >({});
    const [isCompanyDpaModalOpenById, setIsCompanyDpaModalOpenById] = useState<Record<number, boolean>>({});

    function onOpenUserDpaModalClicked() {
        setIsUserDpaModalOpen(true);
    }
    function onCloseUserDpaModalClicked() {
        setIsUserDpaModalOpen(false);
    }
    function onOpenCompanySubscriptionModalClicked(companyId: number) {
        return () => setIsCompanySubscriptionModalOpenById((prev) => ({ ...prev, [companyId]: true }));
    }
    function onCloseCompanySubscriptionModalClicked(companyId: number) {
        return () => setIsCompanySubscriptionModalOpenById((prev) => ({ ...prev, [companyId]: false }));
    }
    function onOpenCompanyDpaModalClicked(companyId: number) {
        return () => setIsCompanyDpaModalOpenById((prev) => ({ ...prev, [companyId]: true }));
    }
    function onCloseCompanyDpaModalClicked(companyId: number) {
        return () => setIsCompanyDpaModalOpenById((prev) => ({ ...prev, [companyId]: false }));
    }

    const userContracts = useAppSelector((state) => state.hydrolink.contracts.userContractsByUserId);

    const userDpas = useMemo(() => userContracts[userId]?.userDpaContracts ?? [], [userContracts, userId]);
    const userDpaToShow = useMemo(() => contractToShow(userDpas), [userDpas]);

    const companySubscriptionContractsByCompanyId = useMemo(
        () => userContracts[userId]?.companySubscriptionContractsByCompanyId ?? {},
        [userContracts, userId],
    );
    const companyDpaContractsByCompanyId = useMemo(
        () => userContracts[userId]?.companyDpaContractsByCompanyId ?? {},
        [userContracts, userId],
    );

    const companyContractsToShow = useMemo(
        () =>
            principallyManagedCompanies
                .map((company) => {
                    const subscription = contractToShow(companySubscriptionContractsByCompanyId[company.id] ?? []);
                    const dpa = contractToShow(companyDpaContractsByCompanyId[company.id] ?? []);
                    const allContractsSigned =
                        subscription?.state === ContractState.ACTIVE && dpa?.state === ContractState.ACTIVE;
                    return {
                        company,
                        subscription,
                        dpa,
                        allContractsSigned,
                    };
                })
                .sort((a, b) => a.company.name.localeCompare(b.company.name)),
        [principallyManagedCompanies, companySubscriptionContractsByCompanyId, companyDpaContractsByCompanyId],
    );
    const someCompanyHasActiveSubscription = useMemo(
        () => companyContractsToShow.some((contracts) => contracts.subscription?.state === ContractState.ACTIVE),
        [companyContractsToShow],
    );

    return (
        <ViewContainer>
            <h2>{t("contracts.pageTitle")}</h2>
            <Block variant="white">
                <h3>{t("contracts.companyContractsSectionTitle")}</h3>
                {companyContractsToShow.map(({ company, subscription, dpa, allContractsSigned }) => (
                    <ExpandableBlock
                        key={company.id}
                        leftSide={
                            <span>
                                {company.name} <CompanyContractStatusIcon allContractsSigned={allContractsSigned} />
                            </span>
                        }
                        grey
                    >
                        <CreateCompanySubscriptionModal
                            isOpen={isCompanySubscriptionModalOpenById[company.id] ?? false}
                            close={onCloseCompanySubscriptionModalClicked(company.id)}
                            userId={userId}
                            companyId={company.id}
                            initialData={{
                                housingCompanyBusinessId: "",
                                housingCompanyStreetAddress: company.streetAddress,
                                housingCompanyZipCode: company.zipCode,
                                housingCompanyTown: company.town,
                                managementCompanyName: "",
                                managementCompanyBusinessId: "",
                                managementCompanyStreetAddress: "",
                                managementCompanyZipCode: "",
                                managementCompanyTown: "",
                                managerPhone: "",
                            }}
                        />
                        <CreateCompanyDpaModal
                            isOpen={isCompanyDpaModalOpenById[company.id] ?? false}
                            close={onCloseCompanyDpaModalClicked(company.id)}
                            userId={userId}
                            companyId={company.id}
                            initialData={{
                                businessId: "",
                                streetAddress: company.streetAddress,
                                zipCode: company.zipCode,
                                town: company.town,
                            }}
                        />
                        <ContractsTable
                            rows={[
                                {
                                    key: 1,
                                    type: t("contracts.types.companySubscription"),
                                    contract: subscription,
                                    signActionDisabledBecause: null,
                                    onSignClicked: onOpenCompanySubscriptionModalClicked(company.id),
                                },
                                {
                                    key: 2,
                                    type: t("contracts.types.companyDpa"),
                                    contract: dpa,
                                    signActionDisabledBecause:
                                        subscription?.state === ContractState.ACTIVE
                                            ? null
                                            : t("contracts.tooltips.thisCompanySubscriptionNotSigned"),
                                    onSignClicked: onOpenCompanyDpaModalClicked(company.id),
                                },
                            ]}
                        />
                    </ExpandableBlock>
                ))}
            </Block>
            <Block variant="white">
                <h3>{t("contracts.principalManagerDpaSectionTitle")}</h3>
                <CreateUserDpaModal
                    isOpen={isUserDpaModalOpen}
                    close={onCloseUserDpaModalClicked}
                    userId={userId}
                    initialData={{
                        managementCompanyBusinessId: "",
                        managementCompanyStreetAddress: "",
                        managementCompanyZipCode: "",
                        managementCompanyTown: "",
                    }}
                />
                <ContractsTable
                    rows={[
                        {
                            key: 1,
                            type: t("contracts.types.principalManagerDpa"),
                            contract: userDpaToShow,
                            signActionDisabledBecause: someCompanyHasActiveSubscription
                                ? null
                                : t("contracts.tooltips.noCompanySubscriptionSigned"),
                            onSignClicked: onOpenUserDpaModalClicked,
                        },
                    ]}
                />
            </Block>
        </ViewContainer>
    );
}
