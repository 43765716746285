import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { ErrorOccurredAction } from "../../redux/error/error-actions";
import { alignments } from "../../theme";
import { CreateUserDPARequestAction } from "../../redux/contracts/contracts-actions";

export type FormData = {
    managementCompanyBusinessId: string;
    managementCompanyStreetAddress: string;
    managementCompanyZipCode: string;
    managementCompanyTown: string;
};

type DialogActionsProps = {
    userId: number;
    data: FormData;
    onClose: () => void;
};

function DialogActions({ userId, data, onClose }: DialogActionsProps) {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const onSave = () => {
        const empty = /^\s*$/;
        if (empty.test(data.managementCompanyBusinessId)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyBusinessIdMissing"));
        } else if (empty.test(data.managementCompanyStreetAddress)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyStreetAddressMissing"));
        } else if (empty.test(data.managementCompanyZipCode)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyZipCodeMissing"));
        } else if (empty.test(data.managementCompanyTown)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyTownMissing"));
        } else {
            dispatch(
                CreateUserDPARequestAction({
                    userId,
                    managementCompanyBusinessId: data.managementCompanyBusinessId,
                    managementCompanyStreetAddress: data.managementCompanyStreetAddress,
                    managementCompanyZipCode: data.managementCompanyZipCode,
                    managementCompanyTown: data.managementCompanyTown,
                }),
            );
            onClose();
        }
    };

    return (
        <>
            <Button onClick={onClose}>{t("generic.cancel")}</Button>
            <Button variant="contained" onClick={onSave}>
                {t("generic.save")}
            </Button>
        </>
    );
}

type MyModalInputProps = {
    field: string;
    value: string;
    onChange: (value: string) => void;
};

function MyModalInput({ field, value, onChange }: MyModalInputProps) {
    const t = useTranslation().t;
    return (
        <ModalInput
            key={field}
            title={t("contracts.forms.createUserDpa.fields." + field)}
            value={value}
            handleFormValue={(e) => onChange(e.target.value)}
        />
    );
}

export type Props = {
    isOpen: boolean;
    close: () => void;
    userId: number;
    initialData: FormData;
};

export function CreateUserDpaModal({ isOpen, close, userId, initialData }: Props) {
    const t = useTranslation().t;

    const [managementCompanyBusinessId, setManagementCompanyBusinessId] = useState(
        initialData.managementCompanyBusinessId,
    );
    const [managementCompanyStreetAddress, setManagementCompanyStreetAddress] = useState(
        initialData.managementCompanyStreetAddress,
    );
    const [managementCompanyZipCode, setManagementCompanyZipCode] = useState(initialData.managementCompanyZipCode);
    const [managementCompanyTown, setManagementCompanyTown] = useState(initialData.managementCompanyTown);

    const data = {
        managementCompanyBusinessId,
        managementCompanyStreetAddress,
        managementCompanyZipCode,
        managementCompanyTown,
    };

    const onClose = () => {
        setManagementCompanyBusinessId(initialData.managementCompanyBusinessId);
        setManagementCompanyStreetAddress(initialData.managementCompanyStreetAddress);
        setManagementCompanyZipCode(initialData.managementCompanyZipCode);
        setManagementCompanyTown(initialData.managementCompanyTown);
        close();
    };

    return (
        <Modal
            title={t("contracts.forms.createUserDpa.title")}
            open={isOpen}
            dialogActions={<DialogActions userId={userId} data={data} onClose={onClose} />}
            closeModal={close}
        >
            <div
                style={{
                    ...alignments.twoElementGrid,
                }}
            >
                <MyModalInput
                    field={"managementCompanyBusinessId"}
                    value={managementCompanyBusinessId}
                    onChange={setManagementCompanyBusinessId}
                />
                <MyModalInput
                    field={"managementCompanyStreetAddress"}
                    value={managementCompanyStreetAddress}
                    onChange={setManagementCompanyStreetAddress}
                />
                <MyModalInput
                    field={"managementCompanyZipCode"}
                    value={managementCompanyZipCode}
                    onChange={setManagementCompanyZipCode}
                />
                <MyModalInput
                    field={"managementCompanyTown"}
                    value={managementCompanyTown}
                    onChange={setManagementCompanyTown}
                />
            </div>
        </Modal>
    );
}
