import { Button } from "../../components/Button";
import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import { useTranslation } from "react-i18next";
import { alignments, borders, colors, spacings, typography } from "../../theme";
import { Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import { AcceptTermsAndConditionsAction, ForceLogoutAction } from "../../redux/auth/auth-actions";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import TOC_fi from "./TOC_fi.md";
import TOC_sv from "./TOC_sv.md";
import { useAppSelector } from "../../utils/store-helpers";
import Spinner from "../../components/Spinner";
import PdfView, { LanguageToUrlMap } from "../../components/PdfView";
import { ViewContainer } from "../../components/ViewContainer";

const containerStyle = {
    ...alignments.centered,
    padding: `0 ${spacings.standardSpacing} 0 ${spacings.standardSpacing}`,
};

const textAreaStyle = {
    ...borders.modalTextAreaBorder,
    display: "block",
    fontSize: typography.fontSize.default,
    backgroundColor: colors.neutral04,
    color: colors.neutral08,
    width: "680px",
    overflow: "auto",
    padding: `${spacings.narrow} ${spacings.standardSpacing} ${spacings.narrow} ${spacings.standardSpacing}`,
};

/** Where to navigate after terms are accepted or dialog is closed */
const navigateTo = "/companies";

/**
 * Mode of the terms and conditions view.
 */
export enum Mode {
    /** Initial terms and conditions acceptance with Cancel/Accept buttons */
    ACCEPT,

    /** Just show the current terms and conditions */
    VIEW,
}

function OldTermsAndConditionsView({ mode }: Props) {
    const t = useTranslation().t;
    const language = useTranslation().i18n.language;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [doc, setDoc] = useState("");

    useEffect(() => {
        const doc = language === "sv" ? TOC_sv : TOC_fi;
        fetch(doc)
            .then((res) => res.text())
            .then((text) => setDoc(text));
    }, [language]);

    const cancel = () => dispatch(ForceLogoutAction());
    const accept = () => dispatch(AcceptTermsAndConditionsAction());
    const closeModal = () => navigate(navigateTo, { replace: true });

    const Buttons = () => {
        if (mode === Mode.ACCEPT) {
            return (
                <>
                    <Button variant="outlined" onClick={cancel}>
                        {t("termsAndConditions.cancel")}
                    </Button>
                    <Button variant="outlined" onClick={accept}>
                        {t("termsAndConditions.accept")}
                    </Button>
                </>
            );
        } else {
            return (
                <Button variant="outlined" onClick={closeModal}>
                    {t("termsAndConditions.ok")}
                </Button>
            );
        }
    };

    return (
        <Dialog open={true} maxWidth="md" fullWidth>
            <div style={containerStyle}>
                <form>
                    <div
                        style={{
                            paddingBottom: spacings.standardSpacing,
                            fontSize: typography.fontSize.modalTitle,
                            textAlign: "center",
                            marginTop: spacings.standardSpacing,
                        }}
                    >
                        {t("termsAndConditions.title")}
                    </div>
                    <div
                        style={{
                            paddingBottom: spacings.standardSpacing,
                            textAlign: "center",
                            overflow: "hidden",
                        }}
                    >
                        {" "}
                        <br></br>
                        <div
                            style={{
                                ...textAreaStyle,
                                textAlign: "left",
                                textJustify: "inter-word",
                            }}
                        >
                            <ReactMarkdown children={doc} />
                        </div>
                    </div>
                    <div
                        style={{
                            ...alignments.evenHorizontal,
                            marginTop: spacings.standardSpacing,
                            marginBottom: spacings.standardSpacing,
                        }}
                    >
                        <Buttons />
                    </div>
                </form>
            </div>
        </Dialog>
    );
}

function NewTermsAndConditionsView({ mode }: Props) {
    const t = useTranslation().t;
    const dispatch = useDispatch();

    const cancel = () => dispatch(ForceLogoutAction());
    const accept = () => dispatch(AcceptTermsAndConditionsAction());

    const languageToUrlMap: LanguageToUrlMap = {
        default: "/documents/terms_of_use_fi.pdf",
        se: "/TODO/documents/terms_of_use_se_sv.pdf",
        sv: "/TODO/documents/terms_of_use_fi_sv.pdf",
    };

    const Buttons = () => {
        if (mode === Mode.ACCEPT) {
            return (
                <>
                    <Button variant="outlined" onClick={cancel}>
                        {t("termsAndConditions.cancel")}
                    </Button>
                    <Button variant="outlined" onClick={accept}>
                        {t("termsAndConditions.accept")}
                    </Button>
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <ViewContainer>
            <div
                style={{
                    paddingBottom: spacings.standardSpacing,
                    fontSize: typography.fontSize.modalTitle,
                    textAlign: "center",
                    marginTop: spacings.standardSpacing,
                }}
            >
                {t("termsAndConditions.title")}
            </div>
            <PdfView title={t("termsAndConditions.title")} languageToUrlMap={languageToUrlMap} />
            <div
                style={{
                    ...alignments.evenHorizontal,
                    marginTop: spacings.standardSpacing,
                    marginBottom: spacings.standardSpacing,
                }}
            >
                <Buttons />
            </div>
        </ViewContainer>
    );
}

function TermsAndConditionsView({ mode }: Props): JSX.Element {
    const navigate = useNavigate();
    const termsAndConditionsAccepted = useAppSelector((state) => state.hydrolink.auth.termsAndConditionsAccepted);
    const contractFeatureEnabled = useAppSelector((state) => state.hydrolink.auth.contractFeatureEnabled);

    useEffect(() => {
        if (mode === Mode.ACCEPT && termsAndConditionsAccepted) {
            navigate(navigateTo, { replace: true });
        }
    }, [mode, termsAndConditionsAccepted, navigate]);

    if (contractFeatureEnabled === undefined) {
        return <Spinner />;
    }
    if (!contractFeatureEnabled) {
        return <OldTermsAndConditionsView mode={mode} />;
    }
    return <NewTermsAndConditionsView mode={mode} />;
}

export type Props = {
    mode: Mode;
};

export default function TermsAndConditions({ mode }: Props): JSX.Element {
    return elementWithHeaderAndFooter(<TermsAndConditionsView mode={mode} />);
}
