import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { ErrorOccurredAction } from "../../redux/error/error-actions";
import { alignments } from "../../theme";
import { CreateCompanySubscriptionRequestAction } from "../../redux/contracts/contracts-actions";

export type FormData = {
    housingCompanyBusinessId: string;
    housingCompanyStreetAddress: string;
    housingCompanyZipCode: string;
    housingCompanyTown: string;
    managementCompanyName: string;
    managementCompanyBusinessId: string;
    managementCompanyStreetAddress: string;
    managementCompanyZipCode: string;
    managementCompanyTown: string;
    managerPhone: string;
};

type DialogActionsProps = {
    userId: number;
    companyId: number;
    data: FormData;
    onClose: () => void;
};

function DialogActions({ userId, companyId, data, onClose }: DialogActionsProps) {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const onSave = () => {
        const empty = /^\s*$/;
        if (empty.test(data.housingCompanyBusinessId)) {
            dispatch(ErrorOccurredAction("errors.housingCompanyBusinessIdMissing"));
        } else if (empty.test(data.housingCompanyStreetAddress)) {
            dispatch(ErrorOccurredAction("errors.housingCompanyStreetAddressMissing"));
        } else if (empty.test(data.housingCompanyZipCode)) {
            dispatch(ErrorOccurredAction("errors.housingCompanyZipCodeMissing"));
        } else if (empty.test(data.housingCompanyTown)) {
            dispatch(ErrorOccurredAction("errors.housingCompanyTownMissing"));
        } else if (empty.test(data.managementCompanyName)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyNameMissing"));
        } else if (empty.test(data.managementCompanyBusinessId)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyBusinessIdMissing"));
        } else if (empty.test(data.managementCompanyStreetAddress)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyStreetAddressMissing"));
        } else if (empty.test(data.managementCompanyZipCode)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyZipCodeMissing"));
        } else if (empty.test(data.managementCompanyTown)) {
            dispatch(ErrorOccurredAction("errors.managementCompanyTownMissing"));
        } else if (empty.test(data.managerPhone)) {
            dispatch(ErrorOccurredAction("errors.managerPhoneMissing"));
        } else {
            dispatch(
                CreateCompanySubscriptionRequestAction({
                    userId,
                    companyId,
                    housingCompanyBusinessId: data.housingCompanyBusinessId,
                    housingCompanyStreetAddress: data.housingCompanyStreetAddress,
                    housingCompanyZipCode: data.housingCompanyZipCode,
                    housingCompanyTown: data.housingCompanyTown,
                    managementCompanyName: data.managementCompanyName,
                    managementCompanyBusinessId: data.managementCompanyBusinessId,
                    managementCompanyStreetAddress: data.managementCompanyStreetAddress,
                    managementCompanyZipCode: data.managementCompanyZipCode,
                    managementCompanyTown: data.managementCompanyTown,
                    managerPhone: data.managerPhone,
                }),
            );
            onClose();
        }
    };

    return (
        <>
            <Button onClick={onClose}>{t("generic.cancel")}</Button>
            <Button variant="contained" onClick={onSave}>
                {t("generic.save")}
            </Button>
        </>
    );
}

type MyModalInputProps = {
    field: string;
    value: string;
    onChange: (value: string) => void;
};

function MyModalInput({ field, value, onChange }: MyModalInputProps) {
    const t = useTranslation().t;
    return (
        <ModalInput
            key={field}
            title={t("contracts.forms.createCompanySubscription.fields." + field)}
            value={value}
            handleFormValue={(e) => onChange(e.target.value)}
        />
    );
}

export type Props = {
    isOpen: boolean;
    close: () => void;
    userId: number;
    companyId: number;
    initialData: FormData;
};

export function CreateCompanySubscriptionModal({ isOpen, close, userId, companyId, initialData }: Props) {
    const t = useTranslation().t;

    const [housingCompanyBusinessId, setHousingCompanyBusinessId] = useState(initialData.housingCompanyBusinessId);
    const [housingCompanyStreetAddress, setHousingCompanyStreetAddress] = useState(
        initialData.housingCompanyStreetAddress,
    );
    const [housingCompanyZipCode, setHousingCompanyZipCode] = useState(initialData.housingCompanyZipCode);
    const [housingCompanyTown, setHousingCompanyTown] = useState(initialData.housingCompanyTown);
    const [managementCompanyName, setManagementCompanyName] = useState(initialData.managementCompanyName);
    const [managementCompanyBusinessId, setManagementCompanyBusinessId] = useState(
        initialData.managementCompanyBusinessId,
    );
    const [managementCompanyStreetAddress, setManagementCompanyStreetAddress] = useState(
        initialData.managementCompanyStreetAddress,
    );
    const [managementCompanyZipCode, setManagementCompanyZipCode] = useState(initialData.managementCompanyZipCode);
    const [managementCompanyTown, setManagementCompanyTown] = useState(initialData.managementCompanyTown);
    const [managerPhone, setManagerPhone] = useState(initialData.managerPhone);

    const data = {
        housingCompanyBusinessId,
        housingCompanyStreetAddress,
        housingCompanyZipCode,
        housingCompanyTown,
        managementCompanyName,
        managementCompanyBusinessId,
        managementCompanyStreetAddress,
        managementCompanyZipCode,
        managementCompanyTown,
        managerPhone,
    };

    const onClose = () => {
        setHousingCompanyBusinessId(initialData.housingCompanyBusinessId);
        setHousingCompanyStreetAddress(initialData.housingCompanyStreetAddress);
        setHousingCompanyZipCode(initialData.housingCompanyZipCode);
        setHousingCompanyTown(initialData.housingCompanyTown);
        setManagementCompanyName(initialData.managementCompanyName);
        setManagementCompanyBusinessId(initialData.managementCompanyBusinessId);
        setManagementCompanyStreetAddress(initialData.managementCompanyStreetAddress);
        setManagementCompanyZipCode(initialData.managementCompanyZipCode);
        setManagementCompanyTown(initialData.managementCompanyTown);
        setManagerPhone(initialData.managerPhone);
        close();
    };

    return (
        <Modal
            title={t("contracts.forms.createCompanySubscription.title")}
            open={isOpen}
            dialogActions={<DialogActions userId={userId} companyId={companyId} data={data} onClose={onClose} />}
            closeModal={close}
        >
            <div
                style={{
                    ...alignments.twoElementGrid,
                }}
            >
                <MyModalInput
                    field={"housingCompanyBusinessId"}
                    value={housingCompanyBusinessId}
                    onChange={setHousingCompanyBusinessId}
                />
                <MyModalInput
                    field={"housingCompanyStreetAddress"}
                    value={housingCompanyStreetAddress}
                    onChange={setHousingCompanyStreetAddress}
                />
                <MyModalInput
                    field={"housingCompanyZipCode"}
                    value={housingCompanyZipCode}
                    onChange={setHousingCompanyZipCode}
                />
                <MyModalInput
                    field={"housingCompanyTown"}
                    value={housingCompanyTown}
                    onChange={setHousingCompanyTown}
                />
                <MyModalInput
                    field={"managementCompanyName"}
                    value={managementCompanyName}
                    onChange={setManagementCompanyName}
                />
                <MyModalInput
                    field={"managementCompanyBusinessId"}
                    value={managementCompanyBusinessId}
                    onChange={setManagementCompanyBusinessId}
                />
                <MyModalInput
                    field={"managementCompanyStreetAddress"}
                    value={managementCompanyStreetAddress}
                    onChange={setManagementCompanyStreetAddress}
                />
                <MyModalInput
                    field={"managementCompanyZipCode"}
                    value={managementCompanyZipCode}
                    onChange={setManagementCompanyZipCode}
                />
                <MyModalInput
                    field={"managementCompanyTown"}
                    value={managementCompanyTown}
                    onChange={setManagementCompanyTown}
                />
                <MyModalInput field={"managerPhone"} value={managerPhone} onChange={setManagerPhone} />
            </div>
        </Modal>
    );
}
