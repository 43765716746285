import { elementWithHeaderAndFooter } from "../../components/common/header/Header";
import PdfView, { LanguageToUrlMap } from "../../components/PdfView";
import { ViewContainer } from "../../components/ViewContainer";

export default function ContractTerms() {
    const languageToUrlMap: LanguageToUrlMap = {
        default: "/documents/contract_terms_fi.pdf",
        se: "/TODO/documents/contract_terms_se_sv.pdf",
        sv: "/TODO/documents/contract_terms_fi_sv.pdf",
    };

    return elementWithHeaderAndFooter(
        <ViewContainer>
            <PdfView title="Contract Terms" languageToUrlMap={languageToUrlMap} />;
        </ViewContainer>,
    );
}
