import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { ErrorOccurredAction } from "../../redux/error/error-actions";
import { alignments } from "../../theme";
import { CreateCompanyDPARequestAction } from "../../redux/contracts/contracts-actions";

export type FormData = {
    businessId: string;
    streetAddress: string;
    zipCode: string;
    town: string;
};

type DialogActionsProps = {
    userId: number;
    companyId: number;
    data: FormData;
    onClose: () => void;
};

function DialogActions({ userId, companyId, data, onClose }: DialogActionsProps) {
    const dispatch = useDispatch();
    const t = useTranslation().t;

    const onSave = () => {
        const empty = /^\s*$/;
        if (empty.test(data.businessId)) {
            dispatch(ErrorOccurredAction("errors.businessIdMissing"));
        } else if (empty.test(data.streetAddress)) {
            dispatch(ErrorOccurredAction("errors.streetAddressMissing"));
        } else if (empty.test(data.zipCode)) {
            dispatch(ErrorOccurredAction("errors.zipCodeMissing"));
        } else if (empty.test(data.town)) {
            dispatch(ErrorOccurredAction("errors.townMissing"));
        } else {
            dispatch(
                CreateCompanyDPARequestAction({
                    userId,
                    companyId,
                    businessId: data.businessId,
                    streetAddress: data.streetAddress,
                    zipCode: data.zipCode,
                    town: data.town,
                }),
            );
            onClose();
        }
    };

    return (
        <>
            <Button onClick={onClose}>{t("generic.cancel")}</Button>
            <Button variant="contained" onClick={onSave}>
                {t("generic.save")}
            </Button>
        </>
    );
}

type MyModalInputProps = {
    field: string;
    value: string;
    onChange: (value: string) => void;
};

function MyModalInput({ field, value, onChange }: MyModalInputProps) {
    const t = useTranslation().t;
    return (
        <ModalInput
            key={field}
            title={t("contracts.forms.createCompanyDpa.fields." + field)}
            value={value}
            handleFormValue={(e) => onChange(e.target.value)}
        />
    );
}

export type Props = {
    isOpen: boolean;
    close: () => void;
    userId: number;
    companyId: number;
    initialData: FormData;
};

export function CreateCompanyDpaModal({ isOpen, close, userId, companyId, initialData }: Props) {
    const t = useTranslation().t;

    const [businessId, setBusinessId] = useState(initialData.businessId);
    const [streetAddress, setStreetAddress] = useState(initialData.streetAddress);
    const [zipCode, setZipCode] = useState(initialData.zipCode);
    const [town, setTown] = useState(initialData.town);

    const data = { businessId, streetAddress, zipCode, town };

    const onClose = () => {
        setBusinessId(initialData.businessId);
        setStreetAddress(initialData.streetAddress);
        setZipCode(initialData.zipCode);
        setTown(initialData.town);
        close();
    };

    return (
        <Modal
            title={t("contracts.forms.createCompanyDpa.title")}
            open={isOpen}
            dialogActions={<DialogActions userId={userId} companyId={companyId} data={data} onClose={onClose} />}
            closeModal={close}
        >
            <div
                style={{
                    ...alignments.twoElementGrid,
                }}
            >
                <MyModalInput field={"businessId"} value={businessId} onChange={setBusinessId} />
                <MyModalInput field={"streetAddress"} value={streetAddress} onChange={setStreetAddress} />
                <MyModalInput field={"zipCode"} value={zipCode} onChange={setZipCode} />
                <MyModalInput field={"town"} value={town} onChange={setTown} />
            </div>
        </Modal>
    );
}
