import { isSwedishVersion } from "../utils/location";
import { retrieveLanguage } from "../utils/languageStore";
import { ASSETS_HOST } from "../constants/urls";

export type LanguageToUrlMap = {
    default: string;
    se: string;
    sv: string;
    [key: string]: string;
};

export type Props = {
    title: string;
    languageToUrlMap: LanguageToUrlMap;
};

export default function PdfView({ title, languageToUrlMap }: Props) {
    const lang = retrieveLanguage();

    const getDocument = (): string => {
        const language = isSwedishVersion() ? "se" : lang;
        const path = languageToUrlMap[language] || languageToUrlMap["default"];
        // Use an absolute path if ASSETS_HOST is defined, otherwise use a relative path
        if (ASSETS_HOST !== undefined) {
            return `${ASSETS_HOST}${path}`;
        }
        return path;
    };

    return (
        <iframe
            title={title}
            style={{
                minHeight: "calc(100vh - 150px)",
                width: "100%",
                marginBottom: "20px",
                border: "0px",
            }}
            src={getDocument()}
        ></iframe>
    );
}
